import {request} from "@/utils/request";
import {UserType} from "../model/user";

export const getCurrent = async () => {
    const  UserType = await request.get("/user/current")
    // @ts-ignore
   // sessionStorage.setItem("longUser", getCurrentUser ? JSON.stringify(getCurrentUser) : JSON.stringify({}))
    return UserType
}

export default getCurrent
