

export const genderMap = {
    Male: "男",
    Female: "女",
};

export const roleMap = {
    Not_ADMIN: "普通用户",
    ADMIN: "管理员",
    VIP: "VIP用户",
};

export const userStatus = {
    Normal: "正常",
    Seal: "已封号",
};
